import { openSignInModalHandler } from '~/account/sign-in/Handlers/openSignInModalHandler';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const FavoritesSignInModal = () => {
	const {
		magicModal,
		globalDynamicStore,
		HREF,
	} = useGlobalContext();

	const openSignInModal = (viewKey: string) => {
		openSignInModalHandler(viewKey, magicModal, globalDynamicStore, HREF);
	};

	openSignInModal('signInFavorites');

	return null;
};
