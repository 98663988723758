import React from 'react';

import { FDSModalWrapper } from '~/components/magic-modal/Components/FDSModal';
import { SwatchOrderingModalWrapper } from '~/components/magic-modal/Components/SwatchOrderingModal';
import { EmailSignUpModalNew } from '~/email-signup/Components/EmailSignUpModalNew';
import { EmailSignUpFormStoreFactory } from '~/email-signup/Stores/emailSignUpForm.store';
import { FreeDesignServicesModalDefault } from '~/free-design-services-modal/Components/freeDesignServicesModalDefault';
import { noop } from '~/util/noop';
import { EmailMMSSignUpModal } from '~/email-signup/Components/EmailMMSSignUpModal';
import { EMAIL_SIGN_UP_FORM_VARIANT } from '~/email-signup/emailSignUp.types';
import { FavoritesSignInModal } from '~/favorites/Components/FavoritesSignInModal';
import { FdsSweepsModalDefault } from '~/fds-sweeps-modal/Components/fdsSweepsModalDefault';
import { MMSGiveawayModalDefault } from '~/mms-giveaway-modal/Components/mmsGiveawayModalDefault';
import { OutdoorSweepsModalDefault } from '~/outdoor-sweeps-modal/Components/outdoorSweepsModalDefault';
import { StorageGiveawayModalDefault } from '~/storage-giveaway-modal/Components/storageGiveawayModalDefault';
import { SwatchOrderingModalDefault } from '~/swatch-ordering/Components/SwatchOrderingModalDefault';
import swatchStyles from '~/swatch-ordering/Components/SwatchOrderModalDefault.module.scss';
import { TextOptInFormVariant, TextOptInSubscriptionSource } from '~/text-opt-in/Types/TextOptIn.constants';
import { MMSOptInModal } from '~/text-opt-in/Components/MMSOptInModal';
import { TextOptInStore } from '~/text-opt-in/Stores/TextOptIn.store';

const getEmailSignUpModalStore = ({
	emailSignUpFormSuccessCallback,
	isMediaQueryMd = false,
	magicSpinnerProps,
	source = '',
	useInlineSubmitButton,
	variant = undefined,
}: {
	emailSignUpFormSuccessCallback?: () => void,
	isMediaQueryMd: Boolean,
	magicSpinnerProps?: any,
	source: string,
	useInlineSubmitButton?: boolean,
	variant?: EMAIL_SIGN_UP_FORM_VARIANT
}) => {
	return EmailSignUpFormStoreFactory.create({
		emailSignUpFormSuccessCallback,
		//@ts-ignore
		hideLegend: true,
		locationId: 'DEFAULT',
		magicSpinnerProps,
		source,
		useInlineSubmitButton: useInlineSubmitButton !== undefined ? useInlineSubmitButton : !isMediaQueryMd,
		variant,
	});
};

export const personalzationModalSettings = {
	FAVORITES_SIGN_IN: () => {
		return {
			WrapperComponent: FDSModalWrapper,
			content: {
				children: <FavoritesSignInModal />,
			},
			title: false,
		};
	},
	FDS_SWEEPS: ({
		isMediaQueryMd = false,
		modalProps = {}
	}) => {
		return {
			fullBleed: true,
			content: {
				children: <FdsSweepsModalDefault {...modalProps} />,
			},
			title: false,
			reverseCloseButton: true,
			width: isMediaQueryMd ? 660 : '90vw',
		};
	},
	FREE_DESIGN_SERVICES: ({
		modalProps = {}
	}) => {
		return {
			WrapperComponent: FDSModalWrapper,
			flushSides: true,
			content: {
				children: <FreeDesignServicesModalDefault {...modalProps} />,
			},
			title: false,
			width: '90vw',
		};
	},
	FIRST_TO_KNOW: ({
		isMediaQueryMd = false,
		modalProps = {},
	}) => {
		//@ts-ignore
		const { interactEventHandler = {} } = modalProps;

		const emailSignUpFormStore = getEmailSignUpModalStore({
			emailSignUpFormSuccessCallback: interactEventHandler,
			isMediaQueryMd,
			source: !isMediaQueryMd ? 'PROACTIVE_MOBILE' : 'PROACTIVE_DESKTOP',
			magicSpinnerProps: {
				isLoading: true,
				minHeight: '50px',
			}
		});

		const additionalModalProps = {
			emailSignUpFormStore,
			headingText: 'Be the first to know!',
			imageName: 'novato_email_signup_modal',
			imageWidth: 270,
			isNoImageEmailSignUpModalContent: !isMediaQueryMd,
			subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
		};

		const combinedModalProps = {
			...modalProps,
			...additionalModalProps,
		};

		return {
			anchorBottom: !isMediaQueryMd,
			content: {
				children: (
					//@ts-ignore
					<EmailSignUpModalNew {...combinedModalProps} />
				),
			},
			dropShadow: !isMediaQueryMd,
			fullBleed: true,
			reverseCloseButton: !isMediaQueryMd,
			showOverlay: isMediaQueryMd,
			title: '',
			width: isMediaQueryMd ? 660 : '95vw',
		};
	},
	FIRST_TO_KNOW_NO_IMAGE_EMAIL_MMS: ({
		isMediaQueryMd = false,
		modalProps = {},
	}) => {
		//@ts-ignore
		const { interactEventHandler = noop } = modalProps;

		const emailSignUpFormStore = getEmailSignUpModalStore({
			isMediaQueryMd,
			emailSignUpFormSuccessCallback: interactEventHandler,
			source: !isMediaQueryMd ? 'PROACTIVE_MOBILE' : 'PROACTIVE_DESKTOP',
			useInlineSubmitButton: false,
			variant: EMAIL_SIGN_UP_FORM_VARIANT.EANDM_WEB,
			magicSpinnerProps: {
				isLoading: true,
				minHeight: '50px',
			}
		});

		const textOptInFormStore = new TextOptInStore({
			source: TextOptInSubscriptionSource.EANDM_WEB,
			variant: TextOptInFormVariant.EMAIL_MMS_MODAL,
		});

		textOptInFormStore.setTextOptInFormSuccessCallback(interactEventHandler);

		const additionalModalProps = {
			emailSignUpFormStore,
			headingText: 'Be the First to Know!',
			imageWidth: 0,
			isNoImageEmailSignUpModalContent: true,
			subheadingText: 'Email and text subscribers are the first to hear about new products and clearance events.',
			textOptInFormStore,
		};

		const combinedModalProps = {
			...modalProps,
			...additionalModalProps,
		};

		return {
			content: {
				children: (
					//@ts-ignore
					<EmailMMSSignUpModal {...combinedModalProps} />
				),
			},
			fullBleed: true,
			reverseCloseButton: true,
			showOverlay: true,
			title: '',
			width: isMediaQueryMd ? 500 : '95vw',
		};
	},
	MMS_GIVEAWAY: ({
		isMediaQueryMd = false,
		modalProps = {}
	}) => {
		return {
			fullBleed: true,
			content: {
				children: <MMSGiveawayModalDefault {...modalProps} />,
			},
			title: false,
			reverseCloseButton: !isMediaQueryMd,
			width: isMediaQueryMd ? 660 : '90vw',
		};
	},
	MMS_OPT_IN: ({
		isMediaQueryMd = false,
		modalProps = {},
	}) => {
		//@ts-ignore
		const { interactEventHandler = noop } = modalProps;

		const textOptInFormStore = new TextOptInStore({
			source: TextOptInSubscriptionSource.MMS_WEB,
			variant: TextOptInFormVariant.MMS_MODAL,
		});

		textOptInFormStore.setTextOptInFormSuccessCallback(interactEventHandler);

		const additionalModalProps = {
			headingText: 'Sign Up for Texts for Exclusive Updates',
			textOptInFormStore,
		};

		const combinedModalProps = {
			...modalProps,
			...additionalModalProps,
		};

		return {
			content: {
				children: (
					//@ts-ignore
					<MMSOptInModal {...combinedModalProps} />
				),
			},
			closeBtnWhiteBackground: 'white',
			fullBleed: true,
			reverseCloseButton: false,
			showOverlay: true,
			title: '',
			width: isMediaQueryMd ? 660 : '95vw',
		};
	},
	OUTDOOR_SWEEPS: ({
		isMediaQueryMd = false,
		modalProps = {}
	}) => {
		return {
			fullBleed: true,
			content: {
				children: <OutdoorSweepsModalDefault {...modalProps} />,
			},
			title: false,
			reverseCloseButton: true,
			width: isMediaQueryMd ? 660 : '90vw',
		};
	},
	STORAGE_GIVEAWAY: ({
		isMediaQueryMd = false,
		modalProps = {}
	}) => {
		return {
			fullBleed: true,
			content: {
				children: <StorageGiveawayModalDefault {...modalProps} />,
			},
			title: false,
			width: isMediaQueryMd ? 660 : '90vw',
		};
	},
	SWATCH_ORDERING: ({
		modalProps = {},
	}) => {
		return {
			WrapperComponent: SwatchOrderingModalWrapper,
			flushSides: true,
			modalClassName: swatchStyles.swatchOrdering,
			content: {
				children: <SwatchOrderingModalDefault {...modalProps} />,
			},
			title: false,
			width: '90vw',
			maxWidth: 640,
		};
	},
};
