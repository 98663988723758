import React from 'react';

import {
	s7ImagePath,
} from '~/global/global.constants';
import { LazyPicture } from '~/util/Components/LazyPicture';
import { Link } from '~/components/Buttons/Components/Link';
import { TextLink } from '~/components/Buttons/Components/TextLink';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

export const FdsSweepsModalDefault = () => {
	return (
		<div>
			<div>
				<div className="">
					<LazyPicture
						smallSrcSet={`
							${s7ImagePath}/fdsSweepsModal_sm?size=414,342&scl=1&cb, 
							${s7ImagePath}/fdsSweepsModal_sm?size=828,684&scl=1&cb 2x
						`}
						mediumSrcSet={`
							${s7ImagePath}/fdsSweepsModal?size=660,400&scl=1&cb, 
							${s7ImagePath}/fdsSweepsModal?size=1320,800&scl=1&cb 2x
						`}
						fallbackSrc={`${s7ImagePath}/fdsSweepsModal?size=660,400&scl=1&cb`} />
				</div>
				<div className="tw-p-4">
					<h2 className="tw-heading-3 tw-mb-3">Free Design Help and a Chance to Win $4,000</h2>
					<p className="tw-mb-5">Now through May 18, meet with a Design Associate in store, online or by phone and you’ll be entered for a chance to win a $4,000 Room & Board gift card.*</p>
					<Link color={ButtonColor.Gray}
						href="/free-design-services/schedule-your-free-appointment"
						variant={ButtonVariant.Hollow}
						isTransparent={true}
					>
								Make a Free Appointment
					</Link>
					<p className="tw-mt-5 md:tw-mb-4 tw-text-2xs tw-text-gray-300">*Void where prohibited. No purchase necessary to enter. Subject to <TextLink href="https://woobox.com/wgixqj/rules" linkExternal={true} target="_blank"><span className="tw-text-2xs">Official Rules</span></TextLink>.</p>
				</div>
			</div>
		</div>
	);
};
